/*
* BUTTONS
*/
.btn {
  // cursor: pointer;
  transition: background 500ms, color 500ms, transform 500ms;
  text-align: center;
}

.btn {
  cursor: url(#{$hand-violet-small}), pointer;
  transition: background 500ms, color 500ms, transform 500ms;
  text-align: center;
}

@media only screen and (max-width: 1024px) {
  .btn {
    cursor: url(#{$hand-violet-small}), pointer;
  }
}

.btn:hover {
  transition: background 500ms, color 500ms, transform 500ms;
}

.btn--c-white {
  background: $color-white;
}

.btn--primary {
  padding: 0.6rem 2rem;
  border-radius: 2.8rem;
  border: 1px solid $color-black;
  color: $color-black;
  background: $color-white;
}

.btn--primary:hover {
  background: $color-violet;
  color: $color-white;
  transform: scale(1.2);
}

.btn--secondary {
  padding: 2rem 4rem;
  border-radius: 7rem;
  background: $color-violet;
  color: $color-white !important;
  transform: scale(1);
  @media only screen and (max-width: 1024px) {
    font-size: 2.2rem;
  }
}

.btn--secondary:hover {
  transform: scale(1.3);
}

.btn--white {
  padding: 0.6rem 3rem;
  border: 2px solid $color-white;
  border-radius: 3rem;
  color: $color-white;
  transition: transform 500ms;
  text-decoration: none;
  @media only screen and (max-width: 1024px) {
    font-size: 2.4rem;
    line-height: 3.4rem;
  }
}

.btn--white:hover {
  background: $color-white;
  color: $color-black;
  transform: scale(1.2);
}

.btn--black {
  padding: 0.6rem 3rem;
  border: 2px solid $color-black;
  border-radius: 3rem;
  color: $color-black;
  transition: transform 500ms;
  text-decoration: none;
}

.btn--black:hover {
  background: $color-violet;
  color: $color-white;
  transform: scale(1.2);
  border: none;
}

.btn--action {
  padding: 5rem;
  background: $color-violet;
  color: $color-white;
  position: fixed;
  bottom: -4rem;
  right: -4rem;
  border-radius: 100%;
  z-index: 10;
  width: 30rem;
  height: 30rem;
  font-size: 3.2rem;
  transform: rotate(-45deg);
  //   word-break: break-word;
  @media only screen and (max-width: 640px) {
    transform: scale(0.6) rotate(-45deg);
    bottom: -8rem;
    right: -8rem;
    line-height: 1.3;
  }
}

.btn--action:hover {
  transform: scale(1.3) rotate(-45deg);
  cursor: url(#{$hand-yellow}), pointer !important;
}
