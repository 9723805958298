/*
* 	NAVIGATION
*/

.nav--primary .nav__link,
.nav--secondary .nav__link,
.nav--language .nav__link {
	color: $color-black;
	transition: color 350ms ease;
	&:hover {
		color: $color-violet;
		transition: color 350ms ease;
	}
}

.nav__item .nav__link:hover {
	transform: none;
}
.nav--primary {
	display: inline-block;
}

.nav--primary .nav__item {
	position: relative;
	margin-right: 4rem;
	display: inline-block;
}

.nav__link:first-child {
	margin-right: 1.5rem;
}

.nav__link:last-child {
	margin-left: 1.5rem;
}

/*
.nav--language::after {
  content: "/";
  display: inline-block;
  margin-left: -50%;
  //transform: translateX(50%);
  pointer-events: none;
}

.nav--language .nav__link:first-child {
  margin-right: 1.5rem;
}

.nav--language .nav__link:last-child {
  margin-left: 1.5rem;
}

.nav--language .nav__link.is-unactive {
  text-decoration: none;
}
*/
