/*
* LISTS
*/

// bullets inside single pages

ul {
	.main & {
		padding-left: 2rem;
		margin-bottom: 4rem;
	}
}

ul li {
	.page-single & {
		list-style-type: disc;
		
	}
}

ul li::before {
	.blog-single & {
		content: "\2022";
		position: absolute;
		top: 0rem;
		left: 0;
	}
	
}

ul li + li {
	.main & {
		margin-top: 2rem;
	}
}

// other lists

.list__item {
	display: inline-block;
	position: relative;
	margin: 1rem 6rem 4rem 7rem;
}

.list__item__caption {
	display: block;
}

.list--primary .list__item {
	margin: 1rem 6rem 4rem 7rem;
	@media only screen and (max-width: 636px) {
		margin: 1rem 2rem 4rem 5rem;
	}
}

.list--horizontal .list__item {
	display: inline-block;
	margin: 0 5rem 0 0;
}

/*
*   EVENTS
*/

.list--events .list__item__btn {
	font-size: 2.4rem!important;
	line-height: 1.3!important;
	padding: 0.6rem 1.6rem!important;
	@media only screen and (min-width: 1024px) {
		min-width: 16rem;
	}
}

.list--events .list__item {
	padding: 3.2rem 0;
	border-top: 2px solid #000;
	margin: 0 !important;
}

@media only screen and (min-width: 767px) {
	.list--events .list__item {
		display: grid;
		grid-template-columns: max-content 1fr 1fr;
		grid-column-gap: 2rem;
	}
}

@media only screen and (min-width: 1024px) {
	.list--events .list__item {
		grid-template-columns: 1fr 2.5fr 1fr;
		grid-column-gap: 4rem;
	}
	
}


.list--events .list__item__left {
	
}
.list--events .list__item__center {
	
}
.list--events .list__item__right {
	display: flex;
	flex-direction: column;
	align-items: center;
	
	//width: 18.478260869565215%;
}
@media only screen and (max-width: 1024px) {
	.list--events .list__item__center {
		padding-left: 4rem;
	}
}
@media only screen and (max-width: 767px) {
	.list--events .list__item {
		display: block;
	}
	.list--events .list__item__left,
	.list--events .list__item__right,
	.list--events .list__item__center {
		width: 100%;
	}
	.list--events .list__item__left {
		margin-bottom: 1.5rem;
	}
	.list--events .list__item__center {
		padding-left: 0;
		margin-bottom: 3rem;
	}
	.list--events .list__item__btn {
		width: 100%;
		box-sizing: border-box;
		text-align: center;
	}
}
