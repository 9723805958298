/*
* HEADER
*/

.header {
	position: fixed;
	width: 100%;
	z-index: 2;
	background: none;
}

.header a {
	cursor: url(#{$hand-violet-small}), pointer;
}

.header.sticky {
	background: $color-violet;
	transition: background 350ms ease;
	padding-bottom: 1.5rem;
	@media only screen and (max-width: 675px) {
		padding-bottom: 2.8rem;
	}
}

.header__body {
	position: relative;
	visibility: visible;
	display: inline-block;
}

.is-visible .header__body {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}

@media only screen and (min-width: 1024px) {
	.header__body {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.header__inner {
	padding: 4.7rem 0 0;
}

.header__burger {
	position: relative;
}

@media only screen and (min-width: 1024px) {
	.header__burger {
		display: none;
	}
}

.burger__item {
	position: absolute;
	right: 0;
	transition: transform 500ms, opacity 500ms, visibility 500ms, background 500ms;
}

// burger visibility

/* .burger__item--open {
  background: red !important;
}

.burger__item--close {
  background: blue !important;
} */

.burger__item--open {
	opacity: 1;
	visibility: visible;
	transform: translateY(3rem);
	.is-visible & {
		opacity: 0;
		visibility: hidden;
		transform: translateY(-3rem);
	}
}

.burger__item--close {
	opacity: 0;
	visibility: hidden;
	transform: translateY(3rem);
	.is-visible & {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
}

.burger__item--open.link {
	transform: translateY(0);
}

.header .nav--primary {
	display: inline-block;
}

.header .nav__item {
	position: relative;
	margin-right: 4rem;
	display: inline-block;
}

.header .nav__item .nav__link:hover {
	transform: none;
}

.header .nav__item .nav__logo__inner:hover {
	background: $color-violet;
	transform: scale(1.4);
}

.header .nav--close {
	position: fixed;
	right: -12rem;
}

.header .nav--close .nav__text {
	transform: rotate(-45deg) translateX(15.5rem);
}

.header .nav__text {
	position: absolute;
	display: block;
	bottom: 0;
	left: 0;
	width: 10rem;
	transform: rotate(-45deg) translateX(10.5rem);
	text-align: right;
}

/*
  Language switch
*/

.header .nav__item--language {
	position: relative;
	margin-right: 0;
}

.header .nav__item--language .nav__link:first-child::after {
	content: "/";
	display: inline-block;
	margin-left: 16px;
	margin-right: 8px;
	pointer-events: none;
}

.header .nav__item--language .nav__link:first-child {
	margin-right: 0;
}

.header .nav__item--language .nav__link:last-child {
	margin-left: 0;
}

.header .nav__item--language .nav__link {
	color: $color-black;
}

.sticky .nav__item--language .nav__link {
	color: $color-white;
}

.is-visible .nav__item--language .nav__link {
	color: $color-black;
}

.header .nav__item--language .nav__link.is-unactive {
	text-decoration: none;
}

// for lower resolutions
@media only screen and (max-width: 1024px) {
	.header {
		z-index: 5;
	}

	.header__inner {
		padding: 1.6rem 0;
	}

	.header__body {
		position: fixed;
		top: 0;
		bottom: 0; //7rem;
		right: 0;
		width: 100vw;
		background: none;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		opacity: 0;
		visibility: hidden;
		transform: translateY(-5rem);
		transition: opacity 500ms, transform 500ms, visibility 500ms,
			background 500ms;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		.is-visible & {
			background: $color-yellow;
			transition: background 500ms;
		}
	}

	.nav--primary {
		margin-top: 8rem;
		margin-left: 4rem;
	}

	.nav--primary .nav__item {
		display: block;
	}

	.nav--primary .nav__item .link {
		@include link;
		/* 		color: $color-black;
		text-decoration: none; */
	}

	.nav--primary .nav__panel {
		position: static;
		max-height: 0;
		margin-left: 3rem;
		overflow: hidden;
		opacity: 0;
		transition: max-height 500ms, opacity 500ms;
	}

	.nav--primary .nav__panel .nav__item {
		margin: 1.5rem 0;
	}

	.nav--primary .nav__panel.is-visible {
		max-height: 40rem;
		opacity: 1;
	}

	.nav__item {
		margin-bottom: 2rem;
		display: block;
	}

	.nav__item--language {
		margin-bottom: 1.7rem;
		margin-left: 4rem;
		color: $color-black;
	}

	.nav__item--language .link {
		color: $color-black;
	}

	.nav__item--language::after {
		display: none;
	}

	.nav__item--language .nav__link:first-child,
	.nav__item--language .nav__link:last-child {
		margin-left: 0;
		margin-right: 0;
	}

	.nav__item--language .nav__link:first-child::after {
		content: "/";
		display: inline-block;
		transform: translateX(50%);
		pointer-events: none;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.nav--action {
		top: auto;
		left: auto;
		bottom: 1.5rem;
		right: 10.5rem;
		width: 9rem;
		height: 9rem;
	}

	.nav--action .nav__text {
		top: 50%;
		bottom: auto;
		max-width: 100%;
		transform: translateY(-50%);
		text-align: center;
	}

	.nav--action:hover {
		transform: rotate(-45deg) scale(2);
	}

	.nav--close {
		right: 1.5rem;
		text-align: center;
	}
}

/* Sticky */

.sticky .link {
	color: $color-white;
}

.sticky.is-visible .link {
	color: $color-black;
}
