/*
* GRID
*/
.row {
  position: relative;
  width: 104%;
  max-width: 100%;
  min-width: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.row.row--no-pad {
  padding-left: 0;
  padding-right: 0;
}
.row:before,
.row:after {
  content: ".";
  display: block;
  height: 0px;
  width: 0px;
  overflow: hidden;
}
.row:after {
  clear: both;
}
.row > [class*="cell"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  float: left;
  position: relative;
  min-height: 1px;
}
.row > [class*="cell"].centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
}
.row > [class*="cell"].u-static {
  position: static;
}
.row > [class*="cell"].collapse {
  padding-left: 0;
  padding-right: 0;
}
.row > .cell-half {
  width: 50%;
}
.row .row {
  width: auto;
  max-width: none;
  min-width: 0;
  margin-left: -3rem;
  margin-right: -3rem;
  @media only screen and (max-width: 675px) {
    margin-left: -2rem;
    margin-right: -2rem;
  }
}

.row--vh {
  min-height: 80vh;
}
.row--full {
  height: 100%;
}
.row--table {
  display: table;
  table-layout: fixed;
}
.row--table--centered > [class*="cell"] {
  display: table-cell;
  vertical-align: middle;
  float: none;
}
.row--flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.row--flex > [class*="cell"] {
  float: none;
}
.row--flex--c > [class*="cell"] {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.row--flex--r > [class*="cell"] {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.row--flex > [class*="cell"] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.row--flex--centered {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.row--flex--centered > [class*="cell"] {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
[class*="cell"] {
  padding-left: 1rem;
  padding-right: 1rem;
}
[class*="cell"].cell-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
[class*="cell"].cell-center {
  align-self: center;
}
[class*="cell"].cell-abs {
  position: absolute;
  top: 0;
  bottom: 0;
  float: none;
}
[class*="cell"].cell-full {
  height: 100%;
}
[class*="cell"].centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
}
.is-ie10 .row--flex > [class*="cell"] * {
  max-width: 100%;
}
.cell-1 {
  width: 8.333333333333334%;
}
.cell-1.cell-abs {
  width: 8.012820512820513%;
}
.prepend-1 {
  margin-left: 8.333333333333334%;
}
.prepend-1.cell-abs {
  margin-left: 8.012820512820513%;
}
.append-1 {
  margin-right: 8.333333333333334%;
}
.append-1.cell-abs {
  margin-right: 8.012820512820513%;
}
.push-1 {
  left: 8.333333333333334%;
}
.push-1.cell-abs {
  left: 8.012820512820513%;
}
.pull-1 {
  right: 8.333333333333334%;
}
.pull-1.cell-abs {
  right: 8.012820512820513%;
}
.cell-2 {
  width: 16.666666666666668%;
}
.cell-2.cell-abs {
  width: 16.025641025641026%;
}
.prepend-2 {
  margin-left: 16.666666666666668%;
}
.prepend-2.cell-abs {
  margin-left: 16.025641025641026%;
}
.append-2 {
  margin-right: 16.666666666666668%;
}
.append-2.cell-abs {
  margin-right: 16.025641025641026%;
}
.push-2 {
  left: 16.666666666666668%;
}
.push-2.cell-abs {
  left: 16.025641025641026%;
}
.pull-2 {
  right: 16.666666666666668%;
}
.pull-2.cell-abs {
  right: 16.025641025641026%;
}
.cell-3 {
  width: 25%;
}
.cell-3.cell-abs {
  width: 24.03846153846154%;
}
.prepend-3 {
  margin-left: 25%;
}
.prepend-3.cell-abs {
  margin-left: 24.03846153846154%;
}
.append-3 {
  margin-right: 25%;
}
.append-3.cell-abs {
  margin-right: 24.03846153846154%;
}
.push-3 {
  left: 25%;
}
.push-3.cell-abs {
  left: 24.03846153846154%;
}
.pull-3 {
  right: 25%;
}
.pull-3.cell-abs {
  right: 24.03846153846154%;
}
.cell-4 {
  width: 33.333333333333336%;
}
.cell-4.cell-abs {
  width: 32.05128205128205%;
}
.prepend-4 {
  margin-left: 33.333333333333336%;
}
.prepend-4.cell-abs {
  margin-left: 32.05128205128205%;
}
.append-4 {
  margin-right: 33.333333333333336%;
}
.append-4.cell-abs {
  margin-right: 32.05128205128205%;
}
.push-4 {
  left: 33.333333333333336%;
}
.push-4.cell-abs {
  left: 32.05128205128205%;
}
.pull-4 {
  right: 33.333333333333336%;
}
.pull-4.cell-abs {
  right: 32.05128205128205%;
}
.cell-5 {
  width: 41.66666666666667%;
}
.cell-5.cell-abs {
  width: 40.06410256410257%;
}
.prepend-5 {
  margin-left: 41.66666666666667%;
}
.prepend-5.cell-abs {
  margin-left: 40.06410256410257%;
}
.append-5 {
  margin-right: 41.66666666666667%;
}
.append-5.cell-abs {
  margin-right: 40.06410256410257%;
}
.push-5 {
  left: 41.66666666666667%;
}
.push-5.cell-abs {
  left: 40.06410256410257%;
}
.pull-5 {
  right: 41.66666666666667%;
}
.pull-5.cell-abs {
  right: 40.06410256410257%;
}
.cell-6 {
  width: 50%;
}
.cell-6.cell-abs {
  width: 48.07692307692308%;
}
.prepend-6 {
  margin-left: 50%;
}
.prepend-6.cell-abs {
  margin-left: 48.07692307692308%;
}
.append-6 {
  margin-right: 50%;
}
.append-6.cell-abs {
  margin-right: 48.07692307692308%;
}
.push-6 {
  left: 50%;
}
.push-6.cell-abs {
  left: 48.07692307692308%;
}
.pull-6 {
  right: 50%;
}
.pull-6.cell-abs {
  right: 48.07692307692308%;
}
.cell-7 {
  width: 58.333333333333336%;
}
.cell-7.cell-abs {
  width: 56.08974358974359%;
}
.prepend-7 {
  margin-left: 58.333333333333336%;
}
.prepend-7.cell-abs {
  margin-left: 56.08974358974359%;
}
.append-7 {
  margin-right: 58.333333333333336%;
}
.append-7.cell-abs {
  margin-right: 56.08974358974359%;
}
.push-7 {
  left: 58.333333333333336%;
}
.push-7.cell-abs {
  left: 56.08974358974359%;
}
.pull-7 {
  right: 58.333333333333336%;
}
.pull-7.cell-abs {
  right: 56.08974358974359%;
}
.cell-8 {
  width: 66.66666666666667%;
}
.cell-8.cell-abs {
  width: 64.1025641025641%;
}
.prepend-8 {
  margin-left: 66.66666666666667%;
}
.prepend-8.cell-abs {
  margin-left: 64.1025641025641%;
}
.append-8 {
  margin-right: 66.66666666666667%;
}
.append-8.cell-abs {
  margin-right: 64.1025641025641%;
}
.push-8 {
  left: 66.66666666666667%;
}
.push-8.cell-abs {
  left: 64.1025641025641%;
}
.pull-8 {
  right: 66.66666666666667%;
}
.pull-8.cell-abs {
  right: 64.1025641025641%;
}
.cell-9 {
  width: 75%;
}
.cell-9.cell-abs {
  width: 72.11538461538461%;
}
.prepend-9 {
  margin-left: 75%;
}
.prepend-9.cell-abs {
  margin-left: 72.11538461538461%;
}
.append-9 {
  margin-right: 75%;
}
.append-9.cell-abs {
  margin-right: 72.11538461538461%;
}
.push-9 {
  left: 75%;
}
.push-9.cell-abs {
  left: 72.11538461538461%;
}
.pull-9 {
  right: 75%;
}
.pull-9.cell-abs {
  right: 72.11538461538461%;
}
.cell-10 {
  width: 83.33333333333334%;
}
.cell-10.cell-abs {
  width: 80.12820512820514%;
}
.prepend-10 {
  margin-left: 83.33333333333334%;
}
.prepend-10.cell-abs {
  margin-left: 80.12820512820514%;
}
.append-10 {
  margin-right: 83.33333333333334%;
}
.append-10.cell-abs {
  margin-right: 80.12820512820514%;
}
.push-10 {
  left: 83.33333333333334%;
}
.push-10.cell-abs {
  left: 80.12820512820514%;
}
.pull-10 {
  right: 83.33333333333334%;
}
.pull-10.cell-abs {
  right: 80.12820512820514%;
}
.cell-11 {
  width: 91.66666666666667%;
}
.cell-11.cell-abs {
  width: 88.14102564102565%;
}
.prepend-11 {
  margin-left: 91.66666666666667%;
}
.prepend-11.cell-abs {
  margin-left: 88.14102564102565%;
}
.append-11 {
  margin-right: 91.66666666666667%;
}
.append-11.cell-abs {
  margin-right: 88.14102564102565%;
}
.push-11 {
  left: 91.66666666666667%;
}
.push-11.cell-abs {
  left: 88.14102564102565%;
}
.pull-11 {
  right: 91.66666666666667%;
}
.pull-11.cell-abs {
  right: 88.14102564102565%;
}
.cell-12 {
  width: 100%;
}
.cell-12.cell-abs {
  width: 96.15384615384616%;
}
.prepend-12 {
  margin-left: 100%;
}
.prepend-12.cell-abs {
  margin-left: 96.15384615384616%;
}
.append-12 {
  margin-right: 100%;
}
.append-12.cell-abs {
  margin-right: 96.15384615384616%;
}
.push-12 {
  left: 100%;
}
.push-12.cell-abs {
  left: 96.15384615384616%;
}
.pull-12 {
  right: 100%;
}
.pull-12.cell-abs {
  right: 96.15384615384616%;
}
@media only screen and (max-width: 1199px) {
  .row {
    overflow: hidden;
    width: auto;
  }
  .row.u-overflow-visible {
    overflow: inherit;
  }
}
@media only screen and (max-width: 1024px) {
  [class*="cell"] {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .row:not(.row--flex) > [class*="prepend-"] {
    margin-left: 0;
  }
  .row:not(.row--flex) > [class*="append-"] {
    margin-right: 0;
  }
  .cell-0-tl {
    width: 0%;
  }
  .prepend-0-tl {
    margin-left: 0% !important;
  }
  .append-0-tl {
    margin-right: 0% !important;
  }
  .cell-1-tl {
    width: 8.333333333333334%;
  }
  .prepend-1-tl {
    margin-left: 8.333333333333334% !important;
  }
  .append-1-tl {
    margin-right: 8.333333333333334% !important;
  }
  .cell-2-tl {
    width: 16.666666666666668%;
  }
  .prepend-2-tl {
    margin-left: 16.666666666666668% !important;
  }
  .append-2-tl {
    margin-right: 16.666666666666668% !important;
  }
  .cell-3-tl {
    width: 25%;
  }
  .prepend-3-tl {
    margin-left: 25% !important;
  }
  .append-3-tl {
    margin-right: 25% !important;
  }
  .cell-4-tl {
    width: 33.333333333333336%;
  }
  .prepend-4-tl {
    margin-left: 33.333333333333336% !important;
  }
  .append-4-tl {
    margin-right: 33.333333333333336% !important;
  }
  .cell-5-tl {
    width: 41.66666666666667%;
  }
  .prepend-5-tl {
    margin-left: 41.66666666666667% !important;
  }
  .append-5-tl {
    margin-right: 41.66666666666667% !important;
  }
  .cell-6-tl {
    width: 50%;
  }
  .prepend-6-tl {
    margin-left: 50% !important;
  }
  .append-6-tl {
    margin-right: 50% !important;
  }
  .cell-7-tl {
    width: 58.333333333333336%;
  }
  .prepend-7-tl {
    margin-left: 58.333333333333336% !important;
  }
  .append-7-tl {
    margin-right: 58.333333333333336% !important;
  }
  .cell-8-tl {
    width: 66.66666666666667%;
  }
  .prepend-8-tl {
    margin-left: 66.66666666666667% !important;
  }
  .append-8-tl {
    margin-right: 66.66666666666667% !important;
  }
  .cell-9-tl {
    width: 75%;
  }
  .prepend-9-tl {
    margin-left: 75% !important;
  }
  .append-9-tl {
    margin-right: 75% !important;
  }
  .cell-10-tl {
    width: 83.33333333333334%;
  }
  .prepend-10-tl {
    margin-left: 83.33333333333334% !important;
  }
  .append-10-tl {
    margin-right: 83.33333333333334% !important;
  }
  .cell-11-tl {
    width: 91.66666666666667%;
  }
  .prepend-11-tl {
    margin-left: 91.66666666666667% !important;
  }
  .append-11-tl {
    margin-right: 91.66666666666667% !important;
  }
  .cell-12-tl {
    width: 100%;
  }
  .prepend-12-tl {
    margin-left: 100% !important;
  }
  .append-12-tl {
    margin-right: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  [class*="cell"] {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .row:not(.row--flex) > [class*="prepend-"] {
    margin-left: 0;
  }
  .row:not(.row--flex) > [class*="append-"] {
    margin-right: 0;
  }
  .cell-0-tp {
    width: 0%;
  }
  .prepend-0-tp {
    margin-left: 0% !important;
  }
  .append-0-tp {
    margin-right: 0% !important;
  }
  .cell-1-tp {
    width: 8.333333333333334%;
  }
  .prepend-1-tp {
    margin-left: 8.333333333333334% !important;
  }
  .append-1-tp {
    margin-right: 8.333333333333334% !important;
  }
  .cell-2-tp {
    width: 16.666666666666668%;
  }
  .prepend-2-tp {
    margin-left: 16.666666666666668% !important;
  }
  .append-2-tp {
    margin-right: 16.666666666666668% !important;
  }
  .cell-3-tp {
    width: 25%;
  }
  .prepend-3-tp {
    margin-left: 25% !important;
  }
  .append-3-tp {
    margin-right: 25% !important;
  }
  .cell-4-tp {
    width: 33.333333333333336%;
  }
  .prepend-4-tp {
    margin-left: 33.333333333333336% !important;
  }
  .append-4-tp {
    margin-right: 33.333333333333336% !important;
  }
  .cell-5-tp {
    width: 41.66666666666667%;
  }
  .prepend-5-tp {
    margin-left: 41.66666666666667% !important;
  }
  .append-5-tp {
    margin-right: 41.66666666666667% !important;
  }
  .cell-6-tp {
    width: 50%;
  }
  .prepend-6-tp {
    margin-left: 50% !important;
  }
  .append-6-tp {
    margin-right: 50% !important;
  }
  .cell-7-tp {
    width: 58.333333333333336%;
  }
  .prepend-7-tp {
    margin-left: 58.333333333333336% !important;
  }
  .append-7-tp {
    margin-right: 58.333333333333336% !important;
  }
  .cell-8-tp {
    width: 66.66666666666667%;
  }
  .prepend-8-tp {
    margin-left: 66.66666666666667% !important;
  }
  .append-8-tp {
    margin-right: 66.66666666666667% !important;
  }
  .cell-9-tp {
    width: 75%;
  }
  .prepend-9-tp {
    margin-left: 75% !important;
  }
  .append-9-tp {
    margin-right: 75% !important;
  }
  .cell-10-tp {
    width: 83.33333333333334%;
  }
  .prepend-10-tp {
    margin-left: 83.33333333333334% !important;
  }
  .append-10-tp {
    margin-right: 83.33333333333334% !important;
  }
  .cell-11-tp {
    width: 91.66666666666667%;
  }
  .prepend-11-tp {
    margin-left: 91.66666666666667% !important;
  }
  .append-11-tp {
    margin-right: 91.66666666666667% !important;
  }
  .cell-12-tp {
    width: 100%;
  }
  .prepend-12-tp {
    margin-left: 100% !important;
  }
  .append-12-tp {
    margin-right: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .row {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .row > [class*="cell"] {
    float: none;
    width: auto !important;
    max-width: 100%;
  }
  .row > [class*="push-"] {
    left: auto;
  }
  .row > [class*="pull-"] {
    right: auto;
  }
  .row.row--break-flex-m,
  .row.row--break-table-m {
    display: block;
  }
  .row.row--break-flex-m > [class*="cell"],
  .row.row--break-table-m > [class*="cell"] {
    width: 100% !important;
    display: block;
  }
  [class*="cell"] {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .cell-0-m {
    width: 0%;
  }
  .prepend-0-m {
    margin-left: 0% !important;
  }
  .append-0-m {
    margin-right: 0% !important;
  }
  .cell-1-m {
    width: 8.333333333333334%;
  }
  .prepend-1-m {
    margin-left: 8.333333333333334% !important;
  }
  .append-1-m {
    margin-right: 8.333333333333334% !important;
  }
  .cell-2-m {
    width: 16.666666666666668%;
  }
  .prepend-2-m {
    margin-left: 16.666666666666668% !important;
  }
  .append-2-m {
    margin-right: 16.666666666666668% !important;
  }
  .cell-3-m {
    width: 25%;
  }
  .prepend-3-m {
    margin-left: 25% !important;
  }
  .append-3-m {
    margin-right: 25% !important;
  }
  .cell-4-m {
    width: 33.333333333333336%;
  }
  .prepend-4-m {
    margin-left: 33.333333333333336% !important;
  }
  .append-4-m {
    margin-right: 33.333333333333336% !important;
  }
  .cell-5-m {
    width: 41.66666666666667%;
  }
  .prepend-5-m {
    margin-left: 41.66666666666667% !important;
  }
  .append-5-m {
    margin-right: 41.66666666666667% !important;
  }
  .cell-6-m {
    width: 50%;
  }
  .prepend-6-m {
    margin-left: 50% !important;
  }
  .append-6-m {
    margin-right: 50% !important;
  }
  .cell-7-m {
    width: 58.333333333333336%;
  }
  .prepend-7-m {
    margin-left: 58.333333333333336% !important;
  }
  .append-7-m {
    margin-right: 58.333333333333336% !important;
  }
  .cell-8-m {
    width: 66.66666666666667%;
  }
  .prepend-8-m {
    margin-left: 66.66666666666667% !important;
  }
  .append-8-m {
    margin-right: 66.66666666666667% !important;
  }
  .cell-9-m {
    width: 75%;
  }
  .prepend-9-m {
    margin-left: 75% !important;
  }
  .append-9-m {
    margin-right: 75% !important;
  }
  .cell-10-m {
    width: 83.33333333333334%;
  }
  .prepend-10-m {
    margin-left: 83.33333333333334% !important;
  }
  .append-10-m {
    margin-right: 83.33333333333334% !important;
  }
  .cell-11-m {
    width: 91.66666666666667%;
  }
  .prepend-11-m {
    margin-left: 91.66666666666667% !important;
  }
  .append-11-m {
    margin-right: 91.66666666666667% !important;
  }
  .cell-12-m {
    width: 100%;
  }
  .prepend-12-m {
    margin-left: 100% !important;
  }
  .append-12-m {
    margin-right: 100% !important;
  }
}

/*
* SPACE & MARGIN
*/

.trailer-1 {
  margin-bottom: 0.1rem;
}
.leader-1 {
  margin-top: 0.1rem;
}
.trailer-2 {
  margin-bottom: 0.2rem;
}
.leader-2 {
  margin-top: 0.2rem;
}
.trailer-3 {
  margin-bottom: 0.3rem;
}
.leader-3 {
  margin-top: 0.3rem;
}
.trailer-4 {
  margin-bottom: 0.4rem;
}
.leader-4 {
  margin-top: 0.4rem;
}
.trailer-5 {
  margin-bottom: 0.5rem;
}
.leader-5 {
  margin-top: 0.5rem;
}
.trailer-6 {
  margin-bottom: 0.6rem;
}
.leader-6 {
  margin-top: 0.6rem;
}
.trailer-7 {
  margin-bottom: 0.7rem;
}
.leader-7 {
  margin-top: 0.7rem;
}
.trailer-8 {
  margin-bottom: 0.8rem;
}
.leader-8 {
  margin-top: 0.8rem;
}
.trailer-9 {
  margin-bottom: 0.9rem;
}
.leader-9 {
  margin-top: 0.9rem;
}
.trailer-10 {
  margin-bottom: 1rem;
}
.leader-10 {
  margin-top: 1rem;
}
.trailer-11 {
  margin-bottom: 1.1rem;
}
.leader-11 {
  margin-top: 1.1rem;
}
.trailer-12 {
  margin-bottom: 1.2rem;
}
.leader-12 {
  margin-top: 1.2rem;
}
.trailer-13 {
  margin-bottom: 1.3rem;
}
.leader-13 {
  margin-top: 1.3rem;
}
.trailer-14 {
  margin-bottom: 1.4rem;
}
.leader-14 {
  margin-top: 1.4rem;
}
.trailer-15 {
  margin-bottom: 1.5rem;
}
.leader-15 {
  margin-top: 1.5rem;
}
.trailer-16 {
  margin-bottom: 1.6rem;
}
.leader-16 {
  margin-top: 1.6rem;
}
.trailer-17 {
  margin-bottom: 1.7rem;
}
.leader-17 {
  margin-top: 1.7rem;
}
.trailer-18 {
  margin-bottom: 1.8rem;
}
.leader-18 {
  margin-top: 1.8rem;
}
.trailer-19 {
  margin-bottom: 1.9rem;
}
.leader-19 {
  margin-top: 1.9rem;
}
.trailer-20 {
  margin-bottom: 2rem;
}
.leader-20 {
  margin-top: 2rem;
}
.trailer-21 {
  margin-bottom: 2.1rem;
}
.leader-21 {
  margin-top: 2.1rem;
}
.trailer-22 {
  margin-bottom: 2.2rem;
}
.leader-22 {
  margin-top: 2.2rem;
}
.trailer-23 {
  margin-bottom: 2.3rem;
}
.leader-23 {
  margin-top: 2.3rem;
}
.trailer-24 {
  margin-bottom: 2.4rem;
}
.leader-24 {
  margin-top: 2.4rem;
}
.trailer-25 {
  margin-bottom: 2.5rem;
}
.leader-25 {
  margin-top: 2.5rem;
}
.trailer-26 {
  margin-bottom: 2.6rem;
}
.leader-26 {
  margin-top: 2.6rem;
}
.trailer-27 {
  margin-bottom: 2.7rem;
}
.leader-27 {
  margin-top: 2.7rem;
}
.trailer-28 {
  margin-bottom: 2.8rem;
}
.leader-28 {
  margin-top: 2.8rem;
}
.trailer-29 {
  margin-bottom: 2.9rem;
}
.leader-29 {
  margin-top: 2.9rem;
}
.trailer-30 {
  margin-bottom: 3rem;
}
.leader-30 {
  margin-top: 3rem;
}
.trailer-31 {
  margin-bottom: 3.1rem;
}
.leader-31 {
  margin-top: 3.1rem;
}
.trailer-32 {
  margin-bottom: 3.2rem;
}
.leader-32 {
  margin-top: 3.2rem;
}
.trailer-33 {
  margin-bottom: 3.3rem;
}
.leader-33 {
  margin-top: 3.3rem;
}
.trailer-34 {
  margin-bottom: 3.4rem;
}
.leader-34 {
  margin-top: 3.4rem;
}
.trailer-35 {
  margin-bottom: 3.5rem;
}
.leader-35 {
  margin-top: 3.5rem;
}
.trailer-36 {
  margin-bottom: 3.6rem;
}
.leader-36 {
  margin-top: 3.6rem;
}
.trailer-37 {
  margin-bottom: 3.7rem;
}
.leader-37 {
  margin-top: 3.7rem;
}
.trailer-38 {
  margin-bottom: 3.8rem;
}
.leader-38 {
  margin-top: 3.8rem;
}
.trailer-39 {
  margin-bottom: 3.9rem;
}
.leader-39 {
  margin-top: 3.9rem;
}
.trailer-40 {
  margin-bottom: 4rem;
}
.leader-40 {
  margin-top: 4rem;
}
.trailer-41 {
  margin-bottom: 4.1rem;
}
.leader-41 {
  margin-top: 4.1rem;
}
.trailer-42 {
  margin-bottom: 4.2rem;
}
.leader-42 {
  margin-top: 4.2rem;
}
.trailer-43 {
  margin-bottom: 4.3rem;
}
.leader-43 {
  margin-top: 4.3rem;
}
.trailer-44 {
  margin-bottom: 4.4rem;
}
.leader-44 {
  margin-top: 4.4rem;
}
.trailer-45 {
  margin-bottom: 4.5rem;
}
.leader-45 {
  margin-top: 4.5rem;
}
.trailer-46 {
  margin-bottom: 4.6rem;
}
.leader-46 {
  margin-top: 4.6rem;
}
.trailer-47 {
  margin-bottom: 4.7rem;
}
.leader-47 {
  margin-top: 4.7rem;
}
.trailer-48 {
  margin-bottom: 4.8rem;
}
.leader-48 {
  margin-top: 4.8rem;
}
.trailer-49 {
  margin-bottom: 4.9rem;
}
.leader-49 {
  margin-top: 4.9rem;
}
.trailer-50 {
  margin-bottom: 5rem;
}
.leader-50 {
  margin-top: 5rem;
}
.trailer-51 {
  margin-bottom: 5.1rem;
}
.leader-51 {
  margin-top: 5.1rem;
}
.trailer-52 {
  margin-bottom: 5.2rem;
}
.leader-52 {
  margin-top: 5.2rem;
}
.trailer-53 {
  margin-bottom: 5.3rem;
}
.leader-53 {
  margin-top: 5.3rem;
}
.trailer-54 {
  margin-bottom: 5.4rem;
}
.leader-54 {
  margin-top: 5.4rem;
}
.trailer-55 {
  margin-bottom: 5.5rem;
}
.leader-55 {
  margin-top: 5.5rem;
}
.trailer-56 {
  margin-bottom: 5.6rem;
}
.leader-56 {
  margin-top: 5.6rem;
}
.trailer-57 {
  margin-bottom: 5.7rem;
}
.leader-57 {
  margin-top: 5.7rem;
}
.trailer-58 {
  margin-bottom: 5.8rem;
}
.leader-58 {
  margin-top: 5.8rem;
}
.trailer-59 {
  margin-bottom: 5.9rem;
}
.leader-59 {
  margin-top: 5.9rem;
}
.trailer-60 {
  margin-bottom: 6rem;
}
.leader-60 {
  margin-top: 6rem;
}
.trailer-61 {
  margin-bottom: 6.1rem;
}
.leader-61 {
  margin-top: 6.1rem;
}
.trailer-62 {
  margin-bottom: 6.2rem;
}
.leader-62 {
  margin-top: 6.2rem;
}
.trailer-63 {
  margin-bottom: 6.3rem;
}
.leader-63 {
  margin-top: 6.3rem;
}
.trailer-64 {
  margin-bottom: 6.4rem;
}
.leader-64 {
  margin-top: 6.4rem;
}
.trailer-65 {
  margin-bottom: 6.5rem;
}
.leader-65 {
  margin-top: 6.5rem;
}
.trailer-66 {
  margin-bottom: 6.6rem;
}
.leader-66 {
  margin-top: 6.6rem;
}
.trailer-67 {
  margin-bottom: 6.7rem;
}
.leader-67 {
  margin-top: 6.7rem;
}
.trailer-68 {
  margin-bottom: 6.8rem;
}
.leader-68 {
  margin-top: 6.8rem;
}
.trailer-69 {
  margin-bottom: 6.9rem;
}
.leader-69 {
  margin-top: 6.9rem;
}
.trailer-70 {
  margin-bottom: 7rem;
}
.leader-70 {
  margin-top: 7rem;
}
.trailer-71 {
  margin-bottom: 7.1rem;
}
.leader-71 {
  margin-top: 7.1rem;
}
.trailer-72 {
  margin-bottom: 7.2rem;
}
.leader-72 {
  margin-top: 7.2rem;
}
.trailer-73 {
  margin-bottom: 7.3rem;
}
.leader-73 {
  margin-top: 7.3rem;
}
.trailer-74 {
  margin-bottom: 7.4rem;
}
.leader-74 {
  margin-top: 7.4rem;
}
.trailer-75 {
  margin-bottom: 7.5rem;
}
.leader-75 {
  margin-top: 7.5rem;
}
.trailer-76 {
  margin-bottom: 7.6rem;
}
.leader-76 {
  margin-top: 7.6rem;
}
.trailer-77 {
  margin-bottom: 7.7rem;
}
.leader-77 {
  margin-top: 7.7rem;
}
.trailer-78 {
  margin-bottom: 7.8rem;
}
.leader-78 {
  margin-top: 7.8rem;
}
.trailer-79 {
  margin-bottom: 7.9rem;
}
.leader-79 {
  margin-top: 7.9rem;
}
.trailer-80 {
  margin-bottom: 8rem;
}
.leader-80 {
  margin-top: 8rem;
}
.trailer-81 {
  margin-bottom: 8.1rem;
}
.leader-81 {
  margin-top: 8.1rem;
}
.trailer-82 {
  margin-bottom: 8.2rem;
}
.leader-82 {
  margin-top: 8.2rem;
}
.trailer-83 {
  margin-bottom: 8.3rem;
}
.leader-83 {
  margin-top: 8.3rem;
}
.trailer-84 {
  margin-bottom: 8.4rem;
}
.leader-84 {
  margin-top: 8.4rem;
}
.trailer-85 {
  margin-bottom: 8.5rem;
}
.leader-85 {
  margin-top: 8.5rem;
}
.trailer-86 {
  margin-bottom: 8.6rem;
}
.leader-86 {
  margin-top: 8.6rem;
}
.trailer-87 {
  margin-bottom: 8.7rem;
}
.leader-87 {
  margin-top: 8.7rem;
}
.trailer-88 {
  margin-bottom: 8.8rem;
}
.leader-88 {
  margin-top: 8.8rem;
}
.trailer-89 {
  margin-bottom: 8.9rem;
}
.leader-89 {
  margin-top: 8.9rem;
}
.trailer-90 {
  margin-bottom: 9rem;
}
.leader-90 {
  margin-top: 9rem;
}
.trailer-91 {
  margin-bottom: 9.1rem;
}
.leader-91 {
  margin-top: 9.1rem;
}
.trailer-92 {
  margin-bottom: 9.2rem;
}
.leader-92 {
  margin-top: 9.2rem;
}
.trailer-93 {
  margin-bottom: 9.3rem;
}
.leader-93 {
  margin-top: 9.3rem;
}
.trailer-94 {
  margin-bottom: 9.4rem;
}
.leader-94 {
  margin-top: 9.4rem;
}
.trailer-95 {
  margin-bottom: 9.5rem;
}
.leader-95 {
  margin-top: 9.5rem;
}
.trailer-96 {
  margin-bottom: 9.6rem;
}
.leader-96 {
  margin-top: 9.6rem;
}
.trailer-97 {
  margin-bottom: 9.7rem;
}
.leader-97 {
  margin-top: 9.7rem;
}
.trailer-98 {
  margin-bottom: 9.8rem;
}
.leader-98 {
  margin-top: 9.8rem;
}
.trailer-99 {
  margin-bottom: 9.9rem;
}
.leader-99 {
  margin-top: 9.9rem;
}
.trailer-100 {
  margin-bottom: 10rem;
}
.leader-100 {
  margin-top: 10rem;
}
.trailer-101 {
  margin-bottom: 10.1rem;
}
.leader-101 {
  margin-top: 10.1rem;
}
.trailer-102 {
  margin-bottom: 10.2rem;
}
.leader-102 {
  margin-top: 10.2rem;
}
.trailer-103 {
  margin-bottom: 10.3rem;
}
.leader-103 {
  margin-top: 10.3rem;
}
.trailer-104 {
  margin-bottom: 10.4rem;
}
.leader-104 {
  margin-top: 10.4rem;
}
.trailer-105 {
  margin-bottom: 10.5rem;
}
.leader-105 {
  margin-top: 10.5rem;
}
.trailer-106 {
  margin-bottom: 10.6rem;
}
.leader-106 {
  margin-top: 10.6rem;
}
.trailer-107 {
  margin-bottom: 10.7rem;
}
.leader-107 {
  margin-top: 10.7rem;
}
.trailer-108 {
  margin-bottom: 10.8rem;
}
.leader-108 {
  margin-top: 10.8rem;
}
.trailer-109 {
  margin-bottom: 10.9rem;
}
.leader-109 {
  margin-top: 10.9rem;
}
.trailer-110 {
  margin-bottom: 11rem;
}
.leader-110 {
  margin-top: 11rem;
}
.trailer-111 {
  margin-bottom: 11.1rem;
}
.leader-111 {
  margin-top: 11.1rem;
}
.trailer-112 {
  margin-bottom: 11.2rem;
}
.leader-112 {
  margin-top: 11.2rem;
}
.trailer-113 {
  margin-bottom: 11.3rem;
}
.leader-113 {
  margin-top: 11.3rem;
}
.trailer-114 {
  margin-bottom: 11.4rem;
}
.leader-114 {
  margin-top: 11.4rem;
}
.trailer-115 {
  margin-bottom: 11.5rem;
}
.leader-115 {
  margin-top: 11.5rem;
}
.trailer-116 {
  margin-bottom: 11.6rem;
}
.leader-116 {
  margin-top: 11.6rem;
}
.trailer-117 {
  margin-bottom: 11.7rem;
}
.leader-117 {
  margin-top: 11.7rem;
}
.trailer-118 {
  margin-bottom: 11.8rem;
}
.leader-118 {
  margin-top: 11.8rem;
}
.trailer-119 {
  margin-bottom: 11.9rem;
}
.leader-119 {
  margin-top: 11.9rem;
}
.trailer-120 {
  margin-bottom: 12rem;
}
.leader-120 {
  margin-top: 12rem;
}
.trailer-121 {
  margin-bottom: 12.1rem;
}
.leader-121 {
  margin-top: 12.1rem;
}
.trailer-122 {
  margin-bottom: 12.2rem;
}
.leader-122 {
  margin-top: 12.2rem;
}
.trailer-123 {
  margin-bottom: 12.3rem;
}
.leader-123 {
  margin-top: 12.3rem;
}
.trailer-124 {
  margin-bottom: 12.4rem;
}
.leader-124 {
  margin-top: 12.4rem;
}
.trailer-125 {
  margin-bottom: 12.5rem;
}
.leader-125 {
  margin-top: 12.5rem;
}
.trailer-126 {
  margin-bottom: 12.6rem;
}
.leader-126 {
  margin-top: 12.6rem;
}
.trailer-127 {
  margin-bottom: 12.7rem;
}
.leader-127 {
  margin-top: 12.7rem;
}
.trailer-128 {
  margin-bottom: 12.8rem;
}
.leader-128 {
  margin-top: 12.8rem;
}
.trailer-129 {
  margin-bottom: 12.9rem;
}
.leader-129 {
  margin-top: 12.9rem;
}
.trailer-130 {
  margin-bottom: 13rem;
}
.leader-130 {
  margin-top: 13rem;
}
.trailer-131 {
  margin-bottom: 13.1rem;
}
.leader-131 {
  margin-top: 13.1rem;
}
.trailer-132 {
  margin-bottom: 13.2rem;
}
.leader-132 {
  margin-top: 13.2rem;
}
.trailer-133 {
  margin-bottom: 13.3rem;
}
.leader-133 {
  margin-top: 13.3rem;
}
.trailer-134 {
  margin-bottom: 13.4rem;
}
.leader-134 {
  margin-top: 13.4rem;
}
.trailer-135 {
  margin-bottom: 13.5rem;
}
.leader-135 {
  margin-top: 13.5rem;
}
.trailer-136 {
  margin-bottom: 13.6rem;
}
.leader-136 {
  margin-top: 13.6rem;
}
.trailer-137 {
  margin-bottom: 13.7rem;
}
.leader-137 {
  margin-top: 13.7rem;
}
.trailer-138 {
  margin-bottom: 13.8rem;
}
.leader-138 {
  margin-top: 13.8rem;
}
.trailer-139 {
  margin-bottom: 13.9rem;
}
.leader-139 {
  margin-top: 13.9rem;
}
.trailer-140 {
  margin-bottom: 14rem;
}
.leader-140 {
  margin-top: 14rem;
}
@media only screen and (max-width: 1024px) {
  .trailer-0-tl {
    margin-bottom: 0rem;
  }
  .leader-0-tl {
    margin-top: 0rem;
  }
  .trailer-10-tl {
    margin-bottom: 1rem;
  }
  .leader-10-tl {
    margin-top: 1rem;
  }
  .trailer-20-tl {
    margin-bottom: 2rem;
  }
  .leader-20-tl {
    margin-top: 2rem;
  }
  .trailer-25-tl {
    margin-bottom: 2.5rem;
  }
  .leader-25-tl {
    margin-top: 2.5rem;
  }
  .trailer-30-tl {
    margin-bottom: 3rem;
  }
  .leader-30-tl {
    margin-top: 3rem;
  }
  .trailer-40-tl {
    margin-bottom: 4rem;
  }
  .leader-40-tl {
    margin-top: 4rem;
  }
  .trailer-50-tl {
    margin-bottom: 5rem;
  }
  .leader-50-tl {
    margin-top: 5rem;
  }
  .trailer-60-tl {
    margin-bottom: 6rem;
  }
  .leader-60-tl {
    margin-top: 6rem;
  }
  .trailer-70-tl {
    margin-bottom: 7rem;
  }
  .leader-70-tl {
    margin-top: 7rem;
  }
  .trailer-80-tl {
    margin-bottom: 8rem;
  }
  .leader-80-tl {
    margin-top: 8rem;
  }
  .trailer-116-tl {
    margin-bottom: 11.6rem;
  }
  .leader-116-tl {
    margin-top: 11.6rem;
  }
  .trailer-140-tl {
    margin-bottom: 14rem;
  }
  .leader-140-tl {
    margin-top: 14rem;
  }
}
@media only screen and (max-width: 768px) {
  .trailer-0-tp {
    margin-bottom: 0rem;
  }
  .leader-0-tp {
    margin-top: 0rem;
  }
  .trailer-10-tp {
    margin-bottom: 1rem;
  }
  .leader-10-tp {
    margin-top: 1rem;
  }
  .trailer-20-tp {
    margin-bottom: 2rem;
  }
  .leader-20-tp {
    margin-top: 2rem;
  }
  .trailer-30-tp {
    margin-bottom: 3rem;
  }
  .leader-30-tp {
    margin-top: 3rem;
  }
  .trailer-40-tp {
    margin-bottom: 4rem;
  }
  .leader-40-tp {
    margin-top: 4rem;
  }
  .trailer-50-tp {
    margin-bottom: 5rem;
  }
  .leader-50-tp {
    margin-top: 5rem;
  }
  .trailer-60-tp {
    margin-bottom: 6rem;
  }
  .leader-60-tp {
    margin-top: 6rem;
  }
  .trailer-140-tp {
    margin-bottom: 14rem;
  }
  .leader-140-tp {
    margin-top: 14rem;
  }
  .is-hidden-tp {
    display: none !important;
  }
  .is-visible-tp {
    display: inherit !important;
  }
}
@media only screen and (max-width: 767px) {
  .u-center-m {
    text-align: center;
  }
  .u-left-m {
    text-align: left;
  }
  .is-hidden-m {
    display: none;
  }
  .trailer-10-m {
    margin-bottom: 1rem;
  }
  .leader-10-m {
    margin-top: 1rem;
  }
  .trailer-20-m {
    margin-bottom: 2rem;
  }
  .leader-20-m {
    margin-top: 2rem;
  }
  .trailer-30-m {
    margin-bottom: 3rem;
  }
  .leader-30-m {
    margin-top: 3rem;
  }
  .trailer-40-m {
    margin-bottom: 4rem;
  }
  .leader-40-m {
    margin-top: 4rem;
  }
  .trailer-48-m {
    margin-bottom: 4.8rem;
  }
  .leader-48-m {
    margin-top: 4.8rem;
  }
  .trailer-50-m {
    margin-bottom: 5rem;
  }
  .leader-50-m {
    margin-top: 5rem;
  }
  .trailer-60-m {
    margin-bottom: 6rem;
  }
  .leader-60-m {
    margin-top: 6rem;
  }
  .trailer-70-m {
    margin-bottom: 7rem;
  }
  .leader-70-m {
    margin-top: 7rem;
  }
  .trailer-140-m {
    margin-bottom: 14rem;
  }
  .leader-140-m {
    margin-top: 14rem;
  }
}

/*
* ZIGZAG
*/

$height: 20px;
$halfheight: ($height/2);
$thickness: 120%; // increase to make the line thicker
$offset: 4px;
$linecolor: black;

.border-zigzag {
  margin: 0 0;
  position: relative;
  height: $height;
  z-index: 1;
  top: -20px;
  line-height: 1;
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }
  &:before {
    height: ($height - $offset);
    top: $thickness;
    background: linear-gradient(-135deg, $linecolor $halfheight, transparent 0)
        0 $halfheight,
      linear-gradient(135deg, $linecolor $halfheight, transparent 0) 0
        $halfheight;
    background-position: top left;
    background-repeat: repeat-x;
    background-size: $height $height;
  }
  &:after {
    height: $height;
    top: 100%;
    background: linear-gradient(-135deg, white $halfheight, transparent 0) 0
        $halfheight,
      linear-gradient(135deg, white $halfheight, transparent 0) 0 $halfheight;
    background-position: top left;
    background-repeat: repeat-x;
    background-size: $height $height;
  }
}
