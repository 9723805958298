/*
* FOOTER
*/

footer {
	background: $color-yellow;
}

footer {
	position: relative;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	z-index: 3;
	@media only screen and (max-width: 1024px) {
		//padding-top: 3rem;
		padding-bottom: 0;
	}
}

.footer a {
	cursor: url(#{$hand-violet-small}), pointer;
}

.footer__inner {
	width: 100%;
	display: flex;
	justify-content: space-between;
	/*   @media only screen and (max-width: 1024px) {
    display: block;
  } */
}
.footer__left .nav__item {
	margin-right: 4rem;
	display: inline-block;
	/*   @media only screen and (max-width: 1024px) {
    display: block;
  } */
}
.footer__right .nav__item {
	position: relative;
	padding-right: 0.5rem;
	/*  @media only screen and (max-width: 1024px) {
    position: absolute;
    top: 0;
    right: 2rem;
  } */
}

.footer__left .nav__item {
	@media only screen and (max-width: 1024px) {
		margin-right: 0;
	}
}

.nav__link:last-child {
	@media only screen and (max-width: 1024px) {
		margin-left: 0;
	}
}
