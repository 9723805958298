/*
* LAYOUT
*/

.main {
	position: relative;
	padding-top: 14rem;
	max-width: 100%;
	min-height: calc(100vh - 75px);
	z-index: 1;
	overflow: hidden;
	@media only screen and (min-width: 1024px) {
		margin-right: 0;
		padding-top: 6rem;
	}
	@media only screen and (max-width: 480px) {
		min-height: calc(100vh - 70px);
	}
}

img,
video {
	display: inline-block;
	max-width: 100%;
	vertical-align: middle;
}

/*
* Page specific styles
*/

// style all sub links
.legal p a,
.page-single p a,
.blog-single p a {
	color: $color-violet;
	@extend .hand-violet;
}

.blog-single p > a {
	// display: inline-block !important;
}

// give all pages a padding
.blog,
.legal,
.page-single,
.blog-single {
	padding-bottom: 4.7rem;
}

@media only screen and (max-width: 650px) {
	.blog,
	.legal,
	.page-single,
	.blog-single {
		padding-bottom: 0;
	}
}

.page-single h1,
.legal h1 {
	@media only screen and (max-width: 650px) {
		font-size: 3rem;
	}
}

.page-single h1 {
	@media only screen and (min-width: 640px) {
		hyphens: auto;
		min-width: 600px;
	}
}

.page-single h1 {
	@media only screen and (min-width: 960px) {
		hyphens: auto;
		min-width: 900px;
	}
}

.page-single h2,
.blog-single h2 {
	@extend .heading-2;
}

.page-single h3,
.blog-single h3 {
	@extend .heading-3;
	@extend .leader-40;
	@extend .trailer-20;
}
.blog .heading-3 {
	@media only screen and (max-width: 636px) {
		font-size: 3rem;
		line-height: 1.3;
		hyphens: auto;
		margin-bottom: 1rem;
	}
}

.page-single p + ul {
	@extend .leader-40;
}

.page-single h4,
.blog-single h4 {
	@extend .heading-4;
	@extend .leader-40;
	@extend .trailer-20;
}

.page-single p + p {
	@extend .leader-20;
}
