/*
* HELPERS & UTILITIES
*/

.c-violet {
  color: $color-violet;
}

.c-yellow {
  color: $color-yellow;
}

.c-black {
  color: $color-black;
}

.c-white {
  color: $color-white;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.u-res {
  width: 100%;
  max-width: 100%;
}
.u-block {
  display: block;
}
.is-hidden {
  display: none !important;
}
.is-vis-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.is-visible-tp {
  display: none !important;
}

@media only screen and (max-width: 650px) {
  .is-hidden-m {
    display: none !important;
  }
}

@media only screen and (max-width: 650px) {
  .is-hidden-tp {
    display: none !important;
  }

  .is-visible-tp {
    display: inherit !important;
  }
}

.u-static {
  position: static;
}
.u-center {
  text-align: center;
}
.u-right {
  text-align: right;
}
.u-align-right {
  float: right;
}
.u-align-left {
  float: left;
}
.u-underline {
  text-decoration: underline;
}
.u-overflow-visible {
  overflow: inherit;
}
.u-force-o-v {
  overflow: visible;
}
.u-flex-grow--full {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.is-visible-tp {
  display: none !important;
}
