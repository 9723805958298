/*  Social share url
    in blog single template
    */

.social-share {
	// border: 2px dashed red;

	@media only screen and (min-width: 1020px) {
		position: fixed;
		right: 2rem;
		top: 50%;
		transform: translateY(-50%);
		display: flex;
		flex-direction: column;
	}
}

.social-share a {
	// background: rgba(yellow, 0.6);
	@include link;
	@media only screen and (min-width: 1020px) {
		flex: 1;
		display: block;
	}
}

.social-share a + a {
	margin-left: 2rem;
	@media only screen and (min-width: 1020px) {
		margin-left: 0;
	}
}
