@import "./minireset";
@import "./globals";
@import "./grid";
@import "./typography";
@import "./layout";
@import "./logo";
@import "./navigation";
@import "./header";
@import "./footer";
@import "./lists";
@import "./buttons";
@import "./utilities";
@import "./share";

/*
* COVERS
*/

.cover--primary {
  margin-top: 17rem;
  padding-bottom: 12rem; //15rem;
  display: inline-block;
  @media only screen and (max-width: 1024px) {
    margin-top: 15rem;
  }
  @media only screen and (max-width: 650px) {
    margin-top: 6rem;
    padding-bottom: 0rem;
  }
}

.cover--secondary {
  display: inline-block;
  padding-top: 20rem;
}

.cover--third {
  margin-top: 8rem;
  padding-bottom: 12rem;
  @media only screen and (max-width: 650px) {
    margin-top: 8rem;
    padding-bottom: 6rem;
  }
}

.cover--centered {
  display: block;
  text-align: center;
}

/*
* PANELS
*/

.stack--text p {
  margin: 1.8rem 0;
}

.stack--text a {
  @include link;
}

.stack--text ul {
  margin-bottom: 1.3rem;
}

.stack--text ul li {
  position: relative;
  padding-left: 4rem;
}

.stack--text ul li::before {
  // content: "\2022";
  // position: absolute;
  // top: 0rem;
  // left: 0;
}

.panel,
.stack {
  overflow: hidden;
}

.panel--violet {
  position: relative;
  background: $color-violet;
}

.panel--violet .panel__inner {
  padding-bottom: 23rem;
}

.panel--yellow {
  padding-top: 4rem;
  padding-bottom: 13rem;
  position: relative;
  background: $color-yellow;
  color: $color-black;
}

.panel--black {
  background: $color-black;
  padding: 5rem 0 7rem;
}

.panel--black .stack--text {
  color: $color-white;
}

/*
* BLOG 
*/

.blog--icon:before {
  color: $color-black;
  transform: rotateY(-360deg) scale(1.5);
  transition: 0.5s;
  transition-delay: 0.1s;
}
.blog--icon {
  position: absolute;
  left: -4rem;
  top: 3rem;
  pointer-events: none;
  @media only screen and (max-width: 636px) {
    left: -2.5rem;
    top: 1rem;
  }
}

.blog--icon:before {
  position: relative;
  display: inline-block;
  top: -0.5rem;
  content: "☞"; //'\263A';
  margin-right: 1rem;
  transition: 0.2s;
  margin-left: -1.5rem;
}

/*
* Map
*/
#map {
  @media only screen and (max-width: 636px) {
    width: 100%;
  }
  @media only screen and (min-width: 1024px) {
    min-height: 450px;
  }
}
