/*
* 	TYPOGRAPHY
*/

.heading-1 {
	@include upper;
	@include regular;
	font-size: 14.5rem; // 17.5rem;
	line-height: 14.5rem;
	letter-spacing: -0.05em;

	// tablet
	@media only screen and (max-width: 1024px) {
		font-size: 5.4rem;
		line-height: 4.9rem;
		letter-spacing: -0.025em;
	}
	//mobile
	@media only screen and (max-width: 650px) {
		font-size: 5.4rem;
		line-height: 4.9rem;
		letter-spacing: 0em;
	}
}

.heading-2 {
	@include regular;
	font-size: 10rem;
	line-height: 10.9rem;
	letter-spacing: -0.25rem;
	@media only screen and (max-width: 1024px) {
		font-size: 4.2rem;
		line-height: 4.6rem;
		letter-spacing: 0em;
	}
}

.heading-3 {
	@include regular;
	font-size: 6.5rem;
	line-height: 7.5rem;
	letter-spacing: -0.1rem;
	@media only screen and (max-width: 1024px) {
		font-size: 3.5rem;
		line-height: 4rem;
	}
}

.heading-4 {
	@include bold;
	font-size: 3.4rem;
	line-height: 4.5rem;
	@media only screen and (max-width: 1024px) {
		font-size: 2.4rem;
		line-height: 3rem;
	}
}

.teasing-1 {
	@include regular;
	font-size: 3.2rem;
	line-height: 4rem;
	@media only screen and (max-width: 1024px) {
		font-size: 2.2rem;
		line-height: 3rem;
	}
}

.caption-1 {
	font-size: 2.2rem;
	line-height: 4rem;
	@media only screen and (max-width: 480px) {
		font-size: 1.5rem;
		line-height: 2.3rem;
	}
}

.caption-2 {
	font-size: 2.2rem;
	line-height: 2.5rem;
	@media only screen and (max-width: 1024px) {
		font-size: 1.6rem;
		line-height: 1.7rem;
	}
}

.caption-3 {
	font-size: 1.3rem;
	line-height: 1.5rem;
	@media only screen and (max-width: 480px) {
		font-size: 1.3rem;
		line-height: 1.5rem;
	}
}

.nav--primary .nav__item {
	font-size: 3rem;
	line-height: 4rem;
	@media only screen and (max-width: 480px) {
		font-size: 4rem;
	}
}

.nav--secondary .nav__item {
	font-size: 2.2rem;
	line-height: 4rem;
	@media only screen and (max-width: 480px) {
		line-height: 1;
		font-size: 1.5rem;
	}
}

.nav--third .nav__item {
	font-size: 5rem;
	@media only screen and (max-width: 1382px) {
		font-size: 3rem;
	}
}

.footer__text {
	font-size: 2.2rem;
	line-height: 3rem;
}

/* 
* Links
*/

@mixin link {
	color: $color-black;
	transition: 500ms transform;
	display: inline-block;

	cursor: url(#{$hand-violet-small}), pointer;
	@media only screen and (max-width: 1024px) {
		cursor: url(#{$hand-violet-small}), pointer;
	}

	&:hover {
		//transform: scale(1.2);
		transform: translateX(-2px);
	}

	&.switch--color.is-active,
	&.switch--color:hover,
	&.is-active,
	&:hover {
		color: $color-violet;
	}
}

@mixin linkUnderline {
	text-decoration: underline;
}

@mixin linkWhite {
	color: $color-white;

	//States
	&.is-active,
	&:hover {
		color: $color-white;
	}
}

@mixin linkViolet {
	color: $color-violet !important;

	//States
	&.is-active,
	&:hover {
		color: $color-violet;
	}
}

.link {
	@include link;
}

.link--underline {
	@include linkUnderline();
}

.link--external {
	// style for external links
}

.link--white,
.u-c-white {
	@include linkWhite();
}

.link--violet,
.u-c-violet {
	@include linkViolet();
}

.nolink {
	color: $color-black;
	pointer-events: none;
	transition: 500ms transform;
	display: inline-block;
	text-decoration: none;

	&:hover {
		transform: scale(1.2);
	}
}

.nounderline {
	text-decoration: none !important;
}
