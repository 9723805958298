/*
* LOGO
*/
/* .header__logo {
  position: relative;
}

.header__logo .logo {
  position: absolute;
  left: 0;
  text-transform: uppercase;
  line-height: 40px;
}

@media only screen and (min-width: 1024px) {
  .header__logo {
    display: none;
  }
} */

/*

TODO:

Take logo out of container 
and make it visible on mobile too
*/
